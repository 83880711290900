import { NextPage } from "next"
import { SITE_CONFIG } from "configs/site"
import { getLayout } from "@common/layouts/landingNew"
import LinkButton from "@common/buttons/linkButton"
import { VT323 } from "next/font/google"
import { joinClassNames } from "@common/lib/util"
import dynamic from "next/dynamic"
import ImageSkeleton from "@common/skeletons/skeletonComponents/imageSkeleton"
import Image from "@common/image"
import Svg from "@common/svg"
import { Fragment } from "react"
import { ScrollProgressBar } from "@common/landing/scrollProgress"

const CrtTypeWriter = dynamic(() => import("components/landing/crtTypeWriter"), {
  loading: () => <ImageSkeleton />,
})

const FeatureWithSideImage = dynamic(
  () => import("@common/landing/featureWithSideImage"),
  {
    loading: () => <ImageSkeleton />,
  }
)

const vt323 = VT323({
  weight: "400",
  subsets: ["latin"],
})

Home.getLayout = (page: NextPage) => getLayout(page, { siteConfig: SITE_CONFIG })
const featureHeaderClass = joinClassNames(vt323.className, "!font-normal !text-5xl")

export default function Home() {
  return (
    <article className="w-full space-y-16 lg:space-y-16">
      <ScrollProgressBar />
      <section className="flex flex-col items-center text-center  px-4 max-w-4xl mx-auto space-y-12">
        <h1
          className={joinClassNames(
            vt323.className,
            "text-6xl md:text-7xl tracking-tight leading-[4rem]"
          )}
        >
          AI Agents to Transform your SEO strategy
        </h1>

        <p className="text-md">
          ACME.BOT automates{" "}
          <span className="font-semibold">
            {" "}
            content research, writing, making illustrations{" "}
          </span>
          and
          <span className="font-semibold"> publishing </span> to dominate search
          rankings and thrive in the Answer Engine Optimization era.
        </p>

        <CrtTypeWriter />

        <div>
          <LinkButton variant="primary" size="lg" href="signup">
            Start Free Trial Now
          </LinkButton>
          <p className="my-2 text-sm">
            No Credit Card Required. Unlock the future of SEO automation.
          </p>
        </div>
      </section>
      <FeatureWithSideImage
        shortHeader="Deep Researched Articles"
        header={
          <h2>
            ACME.BOT's Publish-Ready Articles Actually Add Value To Your Audience.
          </h2>
        }
        description={
          <>
            <br />
            Get rid of robotic AI-slop blog articles that dilutes your credibility.
            <br />
            <br />
            Deliver in-depth, authoritative content that adds value to readers and
            search engines reward.
            <br />
            <br />
            Build trust, drive engagement.
            <br />
            <br />
            Help potentital customers and expand organic traffic reach at the same
            time.
          </>
        }
        imageUrl="/images/landing/books.png"
        headerClass={featureHeaderClass}
        showBar
      />
      <FeatureWithSideImage
        shortHeader="Illustrations"
        header={
          <h2>
            ACME.BOT Creates Visuals That Hooks Readers and Conveys Information
            Quickly
          </h2>
        }
        description={
          <>
            <br />
            In a world where readers often skim, craft unique visuals designed to
            capture attention.
            <br />
            <br />
            Go beyond basic AI images with full-fledged diagrams and illustrations
            <br />
            <br />
            Bring complex ideas and data to life.
            <br />
            <br />
            Images that don't just decorate; but weaves into and amplifies your
            narrative.
          </>
        }
        imageUrl="/images/landing/illustration.png"
        imgLeft
        headerClass={featureHeaderClass}
        showBar
      />
      <FeatureWithSideImage
        shortHeader="Content Strategy and Planning"
        header={<h2>ACME.BOT Helps You Focus On the Bigger Picture</h2>}
        description={
          <>
            <br />
            Stop navigating endless spreadsheets and keyword tools.
            <br />
            <br />
            Scrutinize your competition and dissect the market.
            <br />
            <br />
            Target keywords that matter.
            <br />
            <br />
            Create a content plan based on the analysis with the click of a button.
          </>
        }
        imageUrl="/images/landing/seo.png"
        headerClass={featureHeaderClass}
        showBar
      />
      <FeatureWithSideImage
        header={<h2>ACME.BOT's Configurable Automation Puts You In Command</h2>}
        shortHeader="Configurable Automation"
        description={
          <>
            <br />
            Keep a tight reign with human checkpoints where it counts through email
            workflows and approvals.
            <br />
            <br />
            When confident, engage Auto-publish for total hands-off efficiency
            through publish integrations into platforms like Wordpress and Shopify.
            <br />
            <br />
            You decide.
            <br />
            <br />
            Your workflow. Your rules.
          </>
        }
        imageUrl="/images/landing/automation.png"
        headerClass={featureHeaderClass}
        imgLeft
        showBar
      />

      <StackComparison />

      {/* Benefits Section */}
      <section className="px-4 w-full mx-auto text-center max-w-8xl">
        <h2
          className={joinClassNames(
            vt323.className,
            "text-5xl mb-6 tracking-tight text-center"
          )}
        >
          All the features that will make ACME.BOT your secret weapon
        </h2>

        <ul className="list-none text-left mx-auto grid grid-cols-1 md:grid-cols-2 md:gap-x-8 max-w-xl w-fit *:py-2">
          <li>
            <Svg
              name="check"
              classes="inline size-4 bg-highlight rounded-full p-0.5 text-white mr-2"
            />
            Increased productivity
          </li>
          <li>
            <Svg
              name="check"
              classes="inline size-4 bg-highlight rounded-full p-0.5 text-white mr-2"
            />
            Higher conversion rates
          </li>
          <li>
            <Svg
              name="check"
              classes="inline size-4 bg-highlight rounded-full p-0.5 text-white mr-2"
            />
            Reduced marketing costs
          </li>
          <li>
            <Svg
              name="check"
              classes="inline size-4 bg-highlight rounded-full p-0.5 text-white mr-2"
            />
            More consistent branding
          </li>
          <li>
            <Svg
              name="check"
              classes="inline size-4 bg-highlight rounded-full p-0.5 text-white mr-2"
            />
            Automated content monitoring
          </li>
        </ul>
      </section>
      {/* Integrations Section */}
      <section className="px-4 w-full max-w-8xl mx-auto text-center">
        <h2
          className={joinClassNames(
            vt323.className,
            "text-5xl mb-6 tracking-tight text-center"
          )}
        >
          Integrations
        </h2>

        <div className="flex justify-center items-center space-x-8">
          <img
            src="images/landing/wordpress.png"
            alt="WordPress logo"
            className="w-36 h-auto"
          />
          <img
            src="images/landing/shopify.png"
            alt="Shopify logo"
            className="w-32 h-auto"
          />
        </div>
      </section>
      <section className="text-center">
        <h2
          className={joinClassNames(
            vt323.className,
            "text-5xl tracking-tight text-center"
          )}
        >
          What Business owners Are Saying
        </h2>
        <div className="mx-auto italic flex flex-col sm:flex-row gap-8 max-w-8xl items-center justify-center py-8">
          <div className="space-y-2 w-fit flex flex-col justify-center items-center">
            {/* <div className="rounded-full bg-muted size-24">
              <img src="images/icons/person.png" className="profile-img" />
            </div> */}
            <p className="max-w-72">
              "ACME BOT has our content workflow on full and boosted our traffic!"
            </p>
            <span className="text-warning">★★★★★</span>
            <p className="font-semibold">John, Technical CEO</p>
          </div>

          <div className="space-y-2 w-fit flex flex-col justify-center items-center">
            {/* <div className="rounded-full bg-muted size-24">
              <img src="images/icons/person.png" className="profile-img" />
            </div> */}
            <p className="max-w-72">
              "The illustrations make our blog stand out—clients love it!"
            </p>
            <span className="text-warning">★★★★★</span>
            <p className="font-semibold">Mark, Content Lead</p>
          </div>
        </div>
      </section>
      {/* Free Trial Section */}
      {/* <section className="px-4 w-full max-w-8xl mx-auto text-center pb-6">
        <h2
          className={joinClassNames(
            vt323.className,
            "text-5xl mb-6 tracking-tight text-center"
          )}
        >
          Free Trial
        </h2>
        <h3 className="text-xl mb-6">Try ACME BOT Free for 14 Days</h3>
        <p className="mb-8">
          Experience SEO automation that works for your---starting now..
        </p>

        <Button>Get Your Free Trial Now</Button>
      </section> */}
      <section className="w-full relative">
        <Image src="images/landing/hero-bottom.png" className="w-full" />
        <div className="absolute top-[88%] left-[54%] transform -translate-x-1/2 -translate-y-1/2 p-16">
          <Image
            src="images/icons/bot.png"
            className="animate-bounce size-4 sm:size-8"
          />
        </div>
      </section>
    </article>
  )
}
//TODO: make it reusable component
function StackComparison() {
  const contentWorkflowComparison = [
    {
      section: "KEYWORD RESEARCH & CONTENT PLANNING",
      currentStack: {
        label: "$129-$208/mo",
        descriptions: [
          "Expensive tools like AHREFS ($129/mo)",
          "Hours manually sifting through keyword data",
          "Another $79/mo for SEO auditing tools",
          "Time-consuming manual optimizations",
        ],
      },
      acmeBotSolution: {
        label: "INCLUDED",
        descriptions: [
          "Built-in AI Keyword Planner",
          "Automatic opportunity identification",
          "Competitor gap analysis in seconds",
          "SEO optimization built into the system",
        ],
      },
    },
    {
      section: "CONTENT CREATION & ILLUSTRATION",
      currentStack: {
        label: "$320-$1,200/mo",
        descriptions: [
          "DIY (time you don't have)",
          "Freelancers: $50-$200 per article",
          "Custom illustrations: $30-$100 each",
          "Managing quality and deadlines",
        ],
      },
      acmeBotSolution: {
        label: "INCLUDED",
        descriptions: [
          "AI Content Planner creates optimized articles",
          "Deep-researched content automatically",
          "Custom illustrations generated on demand",
          "Zero freelancer management needed",
        ],
      },
    },
    {
      section: "SCHEDULING & PUBLISHING",
      currentStack: {
        label: "$500-$1,000/mo",
        descriptions: [
          "Error-prone WordPress plugins",
          "Manual scheduling and finger-crossing",
          "Expensive agency management",
          "Missed deadlines and inconsistent posting",
        ],
      },
      acmeBotSolution: {
        label: "INCLUDED",
        descriptions: [
          "Auto Publish on autopilot",
          "Perfect regular posting schedule",
          "No extra costs or stress",
          "Set it and forget it reliability",
        ],
      },
    },
  ]
  return (
    <>
      <h2
        className={joinClassNames(
          vt323.className,
          "text-5xl mb-6 tracking-tight text-center"
        )}
      >
        Your Current Stack vs ACME.BOT Stack
      </h2>
      <section className="grid grid-cols-1 md:grid-cols-3 gap-x-10 gap-y-8 md:gap-y-16 max-w-8xl mx-auto py-6 px-2 md:px-8">
        {contentWorkflowComparison.map((item, index) => {
          return (
            <Fragment key={index}>
              <h2 className="text-xl text-center md:text-start md:text-3xl font-bold">
                {item.section}
              </h2>
              <div className="space-y-4">
                <h3 className="bg-muted px-2 rounded-md w-fit">Current Stack</h3>
                <ul className="space-y-2">
                  <li className="space-y-2">
                    <p>{item.currentStack.label}</p>
                  </li>
                  {item.currentStack.descriptions.map((description, index) => (
                    <li className="flex ml-2" key={index}>
                      <Svg
                        name="star"
                        classes="size-4 text-muted-foreground mr-2"
                        fill="currentColor"
                      />
                      <span>{description}</span>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="space-y-4">
                <h3 className="font-bold rounded-md w-fit">ACME.BOT Stack</h3>
                <ul className="space-y-2">
                  <li className="space-y-2">
                    <p>{item.acmeBotSolution.label}</p>
                  </li>
                  {item.acmeBotSolution.descriptions.map((description, index) => (
                    <li className="flex ml-2" key={index}>
                      <Svg
                        name="star"
                        classes="size-4 text-highlight mr-2"
                        fill="currentColor"
                      />
                      <span>{description}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </Fragment>
          )
        })}
      </section>
    </>
  )
}
