import React from "react"
import { motion, useScroll, useSpring } from "framer-motion"
import { cva, type VariantProps } from "class-variance-authority"
import { joinClassNames } from "@common/lib/util"

const progressBarContainerVariants = cva("w-full fixed left-0 right-0 z-50", {
  variants: {
    position: {
      top: "top-0",
      bottom: "bottom-0",
    },
    size: {
      sm: "h-1",
      md: "h-2",
      lg: "h-4",
      xl: "h-6",
    },
    background: {
      transparent: "bg-transparent",
      muted: "bg-muted",
    },
  },
  defaultVariants: {
    position: "top",
    size: "md",
    background: "transparent",
  },
})

const progressBarIndicatorVariants = cva("w-full h-full origin-left", {
  variants: {
    color: {
      primary: "bg-primary",
      secondary: "bg-secondary",
      highlight: "bg-highlight",
    },
  },
  defaultVariants: {
    color: "primary",
  },
})

export interface ScrollProgressBarProps
  extends VariantProps<typeof progressBarContainerVariants>,
    VariantProps<typeof progressBarIndicatorVariants> {
  backgroundColor?: string
  zIndex?: number
  className?: string
}

export const ScrollProgressBar = ({
  color = "primary",
  size = "sm",
  position = "top",
  className,
}: ScrollProgressBarProps) => {
  const { scrollYProgress } = useScroll()
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  })

  return (
    <div
      className={joinClassNames(
        progressBarContainerVariants({ position, size }),
        className
      )}
    >
      <motion.div
        className={progressBarIndicatorVariants({ color })}
        style={{ scaleX }}
      />
    </div>
  )
}
